import { useState } from "react";
import { useApi } from "../../api";
import {
  CONTENTFUL_CONTENT_RESPONSE,
  CONTENTFUL_GET_ASSETS_RESPONSE,
} from "../../api/contentful";
import { Modal } from "../../components/modal";
import { ProductList } from "../../components/product_list";
import { useAsyncMemo } from "../../hooks/useAsyncMemo";

export const DesignCatalog = () => {
  const api = useApi();
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const contentfulEntry = useAsyncMemo(
    async () => {
      const response = await api.contentful.getEntry("2rhekRGmdvo5J5Q93Rmy5F");
      if (response.type !== CONTENTFUL_CONTENT_RESPONSE) return null;
      return response.data.fields;
    },
    [],
    null
  );
  const contentfulAssets = useAsyncMemo(
    async () => {
      const response = await api.contentful.getAssets();
      if (response.type !== CONTENTFUL_GET_ASSETS_RESPONSE) return null;
      return response.data.items;
    },
    [],
    null
  );

  const getProducts = (tag: string) => {
    let products: string[] = [];
    if (contentfulAssets) {
      contentfulAssets.forEach((x) => {
        if (x.metadata.tags.length > 0 && x.metadata.tags[0].sys.id === tag) {
          products.push(x.fields.file.url);
        }
      });
    }

    return products;
  };

  if (contentfulEntry !== null && contentfulAssets !== null) {
    return (
      <div className="h-full w-4/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 space-x-4 justify-center">
        <Modal src={image} onClick={() => setImage("")} />
        <div className="flex flex-col md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:w-3/5 md:p-2 mb-1">
            <img className="w-full" src={contentfulEntry.image1} alt="" />
          </div>
          <div className="grid grid-cols-1 content-center self-stretch md:w-2/5 md:p-2 mb-1">
            <img className="w-full" src={contentfulEntry.image2} alt="" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:w-3/5 md:p-2">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentfulEntry.header1}
            </h1>
            <p className="py-2 text-left">{contentfulEntry.text1}</p>
          </div>
          <div className="grid grid-cols-1 content-center self-start md:w-2/5 md:p-2">
            <h1 className="text-2xl pb-1 text-left theme-color">
              {contentfulEntry.header2}
            </h1>
            <p className="py-2 text-left">{contentfulEntry.text2}</p>
            <h1 className="text-2xl py-1 text-left">
              {contentfulEntry.header3}
            </h1>
            <p className="py-2 text-left">{contentfulEntry.text3}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:p-2">
            <h1 className="md:text-3xl text-2xl py-1 text-left">
              {contentfulEntry.header4}
            </h1>
            <p className="py-2 text-left">{contentfulEntry.text4}</p>
          </div>
        </div>
        <ProductList
          header={contentfulEntry.header5}
          products={getProducts("coilRings")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
        <ProductList
          header={contentfulEntry.header6}
          products={getProducts("weddingBands")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
        <ProductList
          header={contentfulEntry.header7}
          products={getProducts("earRing")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
        <ProductList
          header={contentfulEntry.header8}
          products={getProducts("pendants")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
        <ProductList
          header={contentfulEntry.header9}
          products={getProducts("bracelets")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
        <ProductList
          header={contentfulEntry.header10}
          products={getProducts("skullRing")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
        <ProductList
          header={contentfulEntry.header11}
          products={getProducts("raceCars")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
        <ProductList
          header={contentfulEntry.header12}
          products={getProducts("")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
        <ProductList
          header={contentfulEntry.header13}
          products={getProducts("")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
        <ProductList
          header={contentfulEntry.header14}
          products={getProducts("")}
          loading={loading}
          onLoad={() => setLoading(false)}
          onClick={(e) => setImage(e)}
        />
      </div>
    );
  }

  return null;
};
