type APIInnerMessage = {
    id: string
    message: string
}
type APIMessage = {
    messages: APIInnerMessage[]
}
export const API_ERROR_RESPONSE = 'API_ERROR_RESPONSE'
export type APIErrorResponse = {
    type: typeof API_ERROR_RESPONSE
    statusCode: number
    error: string
    message: APIMessage[] | string
    data: APIMessage[]
}