import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { routes } from './routes'

import { Navbar } from './components/navbar'
import { Footer } from './components/footer'

export const App = () => {
    return (
        <div className='min-h-screen theme-bg'>
            <Navbar />
            <section className='w-full flex justify-center h-auto'>
                <Switch>
                    {routes.map(({ path, component }) => (
                        <Route exact key={path} path={path} component={component} />
                    ))}
                </Switch>
            </section>
            <Footer />
        </div>
    )
}
