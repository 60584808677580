import { APIErrorResponse, API_ERROR_RESPONSE } from "./types";
import type { AxiosInstance } from "axios";
import Configuration from "../configuration.json";

const CONTENTFUL_ACCESS_TOKEN = Configuration.CONTENTFUL_ACCESS_TOKEN;

export type ContentfulContent = {
  name: string;
  header1: string;
  header2: string;
  header3: string;
  header4: string;
  header5: string;
  header6: string;
  header7: string;
  header8: string;
  header9: string;
  header10: string;
  header11: string;
  header12: string;
  header13: string;
  header14: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  text6: string;
  text7: string;
  text8: string;
  text9: string;
  text10: string;
  text11: string;
  text12: string;
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
  image6: string;
  image7: string;
  image8: string;
  image9: string;
  image10: string;
  image11: string;
  image12: string;
  image13: string;
  image14: string;
  image15: string;
  image16: string;
  image17: string;
  image18: string;
  event1: ContentfulEvent;
  event2: ContentfulEvent;
  event3: ContentfulEvent;
  event4: ContentfulEvent;
  event5: ContentfulEvent;
  event6: ContentfulEvent;
  event7: ContentfulEvent;
  event8: ContentfulEvent;
  event9: ContentfulEvent;
  event10: ContentfulEvent;
  event11: ContentfulEvent;
  event12: ContentfulEvent;
  event13: ContentfulEvent;
  event14: ContentfulEvent;
  event15: ContentfulEvent;
  event16: ContentfulEvent;
  event17: ContentfulEvent;
  event18: ContentfulEvent;
  event19: ContentfulEvent;
  event20: ContentfulEvent;
};

export type ContentfulEvent = {
  name: string;
  date: string;
  time: string;
  localLocation: string;
  regionLocation: string;
}

export type ContentfulGetAssetsContent = {
  description: string;
  file: ContentfulGetAssetsFile;
};

export type ContentfulGetAssetsFile = {
  url: string;
};

export type ContentfulFields = {
  fields: ContentfulContent;
};

export type ContentfulGetAssetsFields = {
  metadata: ContentfulGetAssetsMetaData;
  fields: ContentfulGetAssetsContent;
};

export type ContentfulGetAssetsMetaData = {
  tags: ContentfulGetAssetsMetaDataTags[];
};

export type ContentfulGetAssetsMetaDataTags = {
  sys: ContentfulGetAssetsMetaDataSys;
};

export type ContentfulGetAssetsMetaDataSys = {
  id: string;
};

export type ContentfulItems = {
  items: ContentfulFields[];
};

export type ContentfulGetAssetsItems = {
  items: ContentfulGetAssetsFields[];
};

export type ContentfulContentResponse = {
  type: typeof CONTENTFUL_CONTENT_RESPONSE;
  data: ContentfulFields;
};

export type ContentfulContentQueryResponse = {
  type: typeof CONTENTFUL_CONTENT_QUERY_RESPONSE;
  data: ContentfulItems;
};

export type ContentfulGetAssetsResponse = {
  type: typeof CONTENTFUL_GET_ASSETS_RESPONSE;
  data: ContentfulGetAssetsItems;
};

export const CONTENTFUL_CONTENT_RESPONSE = "CONTENTFUL_CONTENT_RESPONSE";
export const CONTENTFUL_CONTENT_QUERY_RESPONSE =
  "CONTENTFUL_CONTENT_QUERY_RESPONSE";
export const CONTENTFUL_GET_ASSETS_RESPONSE = "CONTENTFUL_GET_ASSETS_RESPONSE";

export const contentfulAPI = (api: AxiosInstance) => ({
  contentful: {
    getEntry: async (
      page: string
    ): Promise<ContentfulContentResponse | APIErrorResponse> =>
      await api
        .get(
          "/spaces/126kiel6w518/entries/" +
            page +
            "?access_token=" +
            CONTENTFUL_ACCESS_TOKEN
        )
        .then(
          ({ data }) => {
            return { data: data, type: CONTENTFUL_CONTENT_RESPONSE };
          },
          ({ response }) => ({
            ...(response || { data: {} }).data,
            type: API_ERROR_RESPONSE,
          })
        ),
    getAssets: async (): Promise<
      ContentfulGetAssetsResponse | APIErrorResponse
    > =>
      await api
        .get(
          "/spaces/126kiel6w518/assets?access_token=" + CONTENTFUL_ACCESS_TOKEN
        )
        .then(
          ({ data }) => {
            return { data: data, type: CONTENTFUL_GET_ASSETS_RESPONSE };
          },
          ({ response }) => ({
            ...(response || { data: {} }).data,
            type: API_ERROR_RESPONSE,
          })
        ),
    queryEntries: async (): Promise<
      ContentfulContentQueryResponse | APIErrorResponse
    > =>
      await api
        .get(
          "/spaces/126kiel6w518/entries?access_token=" +
            CONTENTFUL_ACCESS_TOKEN +
            "&content_type=products"
        )
        .then(
          ({ data }) => {
            return { data: data, type: CONTENTFUL_CONTENT_QUERY_RESPONSE };
          },
          ({ response }) => ({
            ...(response || { data: {} }).data,
            type: API_ERROR_RESPONSE,
          })
        ),
  },
});
