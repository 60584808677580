import React from "react";

type props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button: React.FunctionComponent<props> = ({
  className,
  children,
  ...props
}) => {
  return (
    <button
      className={`fit-content inline-flex whitespace-nowrap px-4 py-2 rounded-md ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};
