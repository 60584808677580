import { useApi } from "../../api";
import { CONTENTFUL_CONTENT_RESPONSE } from "../../api/contentful";
import { useAsyncMemo } from "../../hooks/useAsyncMemo";

export const UpcomingEvents = () => {
  const api = useApi();
  const contentful = useAsyncMemo(
    async () => {
      const response = await api.contentful.getEntry("4hh7ZQSqwlj2Q4kcTG3Fei");
      if (response.type !== CONTENTFUL_CONTENT_RESPONSE) return null;
      return response.data.fields;
    },
    [],
    null
  );

  if (contentful !== null) {
    return (
      <div className="h-full w-4/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 space-x-4 justify-center">
        <div className="grid grid-cols-1 content-center p-4">
          <div className="col-span1 webkit-center px-1 py-1">
            <h1 className="text-1xl font-medium md:text-3xl py-1">
              {contentful.header1}
            </h1>
            <p className="font-medium py-1">{contentful.text1}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
          <img className="w-full md:p-1 mb-1" src={contentful.image1} alt="" />
          <img className="w-full md:p-1 mb-1" src={contentful.image2} alt="" />
        </div>
        {contentful.event1?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event1?.name}</h1>
              <p className="py-1">{contentful.event1?.date}</p>
              <p className="py-1">{contentful.event1?.time}</p>
              <p className="py-1">{contentful.event1?.localLocation}</p>
              <p className="py-1">{contentful.event1?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event2?.name}</h1>
              <p className="py-1">{contentful.event2?.date}</p>
              <p className="py-1">{contentful.event2?.time}</p>
              <p className="py-1">{contentful.event2?.localLocation}</p>
              <p className="py-1">{contentful.event2?.regionLocation}</p>
            </div>
          </div>
        )}
        {contentful.event3?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event3?.name}</h1>
              <p className="py-1">{contentful.event3?.date}</p>
              <p className="py-1">{contentful.event3?.time}</p>
              <p className="py-1">{contentful.event3?.localLocation}</p>
              <p className="py-1">{contentful.event3?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event4?.name}</h1>
              <p className="py-1">{contentful.event4?.date}</p>
              <p className="py-1">{contentful.event4?.time}</p>
              <p className="py-1">{contentful.event4?.localLocation}</p>
              <p className="py-1">{contentful.event4?.regionLocation}</p>
            </div>
          </div>
        )}
        {contentful.event5?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event5?.name}</h1>
              <p className="py-1">{contentful.event5?.date}</p>
              <p className="py-1">{contentful.event5?.time}</p>
              <p className="py-1">{contentful.event5?.localLocation}</p>
              <p className="py-1">{contentful.event5?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event6?.name}</h1>
              <p className="py-1">{contentful.event6?.date}</p>
              <p className="py-1">{contentful.event6?.time}</p>
              <p className="py-1">{contentful.event6?.localLocation}</p>
              <p className="py-1">{contentful.event6?.regionLocation}</p>
            </div>
          </div>
        )}
        {contentful.event7?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event7?.name}</h1>
              <p className="py-1">{contentful.event7?.date}</p>
              <p className="py-1">{contentful.event7?.time}</p>
              <p className="py-1">{contentful.event7?.localLocation}</p>
              <p className="py-1">{contentful.event7?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event8?.name}</h1>
              <p className="py-1">{contentful.event8?.date}</p>
              <p className="py-1">{contentful.event8?.time}</p>
              <p className="py-1">{contentful.event8?.localLocation}</p>
              <p className="py-1">{contentful.event8?.regionLocation}</p>
            </div>
          </div>
        )}
        {contentful.event9?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event9?.name}</h1>
              <p className="py-1">{contentful.event9?.date}</p>
              <p className="py-1">{contentful.event9?.time}</p>
              <p className="py-1">{contentful.event9?.localLocation}</p>
              <p className="py-1">{contentful.event9?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event10?.name}</h1>
              <p className="py-1">{contentful.event10?.date}</p>
              <p className="py-1">{contentful.event10?.time}</p>
              <p className="py-1">{contentful.event10?.localLocation}</p>
              <p className="py-1">{contentful.event10?.regionLocation}</p>
            </div>
          </div>
        )}
        {contentful.event11?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event11?.name}</h1>
              <p className="py-1">{contentful.event11?.date}</p>
              <p className="py-1">{contentful.event11?.time}</p>
              <p className="py-1">{contentful.event11?.localLocation}</p>
              <p className="py-1">{contentful.event11?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event12?.name}</h1>
              <p className="py-1">{contentful.event12?.date}</p>
              <p className="py-1">{contentful.event12?.time}</p>
              <p className="py-1">{contentful.event12?.localLocation}</p>
              <p className="py-1">{contentful.event12?.regionLocation}</p>
            </div>
          </div>
        )}
        {contentful.event13?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event13?.name}</h1>
              <p className="py-1">{contentful.event13?.date}</p>
              <p className="py-1">{contentful.event13?.time}</p>
              <p className="py-1">{contentful.event13?.localLocation}</p>
              <p className="py-1">{contentful.event13?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event14?.name}</h1>
              <p className="py-1">{contentful.event14?.date}</p>
              <p className="py-1">{contentful.event14?.time}</p>
              <p className="py-1">{contentful.event14?.localLocation}</p>
              <p className="py-1">{contentful.event14?.regionLocation}</p>
            </div>
          </div>
        )}
        {contentful.event15?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event15?.name}</h1>
              <p className="py-1">{contentful.event15?.date}</p>
              <p className="py-1">{contentful.event15?.time}</p>
              <p className="py-1">{contentful.event15?.localLocation}</p>
              <p className="py-1">{contentful.event15?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event16?.name}</h1>
              <p className="py-1">{contentful.event16?.date}</p>
              <p className="py-1">{contentful.event16?.time}</p>
              <p className="py-1">{contentful.event16?.localLocation}</p>
              <p className="py-1">{contentful.event16?.regionLocation}</p>
            </div>
          </div>
        )}
        {contentful.event17?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event17?.name}</h1>
              <p className="py-1">{contentful.event17?.date}</p>
              <p className="py-1">{contentful.event17?.time}</p>
              <p className="py-1">{contentful.event17?.localLocation}</p>
              <p className="py-1">{contentful.event17?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event18?.name}</h1>
              <p className="py-1">{contentful.event18?.date}</p>
              <p className="py-1">{contentful.event18?.time}</p>
              <p className="py-1">{contentful.event18?.localLocation}</p>
              <p className="py-1">{contentful.event18?.regionLocation}</p>
            </div>
          </div>
        )}
        {contentful.event19?.name && (
          <div className="grid grid-cols-1 md:grid-cols-2 content-center p-4">
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event19?.name}</h1>
              <p className="py-1">{contentful.event19?.date}</p>
              <p className="py-1">{contentful.event19?.time}</p>
              <p className="py-1">{contentful.event19?.localLocation}</p>
              <p className="py-1">{contentful.event19?.regionLocation}</p>
            </div>
            <div className="col-span1 webkit-center self-start px-1">
              <h1 className="text-1xl py-1">{contentful.event20?.name}</h1>
              <p className="py-1">{contentful.event20?.date}</p>
              <p className="py-1">{contentful.event20?.time}</p>
              <p className="py-1">{contentful.event20?.localLocation}</p>
              <p className="py-1">{contentful.event20?.regionLocation}</p>
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};
