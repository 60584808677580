import { Home } from './pages/home'
import { DesignCatalog } from './pages/design-catalog'
import { About } from './pages/about'
import { Contact } from './pages/contact'
import { Services } from './pages/services'
import { UpcomingEvents } from './pages/upcoming-events'
import { Testimonials } from './pages/testimonials'

export const routes = [
    {
        path: '/(|home/3040677)',
        component: Home
    },
    {
        path: '/about-our-designer/3041897',
        component: About
    },
    {
        path: '/services/3041898',
        component: Services
    },
    {
        path: '/contact-us/3040681',
        component: Contact
    },
    {
        path: '/design-catalog/3040680',
        component: DesignCatalog
    },
    {
        path: '/testimonials',
        component: Testimonials
    },
    {
        path: '/upcoming-events/3075154',
        component: UpcomingEvents
    },
]
