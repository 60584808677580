import React from "react";
import { Fade } from "react-awesome-reveal";

type props = {
  header: string;
  products: string[];
  loading?: boolean;
  onLoad?: () => any;
  onClick: (src: string) => any;
};

export const ProductList: React.FunctionComponent<props> = ({
  header,
  products,
  loading,
  onLoad,
  onClick,
}) => {
  return (
    <div className="m-auto">
      <div className="flex flex-col md:flex-row ml-none">
        <div className="grid grid-cols-1 content-center self-start md:p-2">
          <h1 className="md:text-3xl text-2xl py-1 text-left italic">
            {header}
          </h1>
        </div>
      </div>
      <div className="flex flex-col md:flex-row ml-none">
        <div className="grid grid-cols-4 md:grid-cols-6 content-center self-start items-center w-full">
          {products.map((src, i) => (
            <img
              className={loading ? "hidden" : "w-full p-1 cursor-pointer"}
              src={src}
              key={i}
              onLoad={onLoad}
              onClick={() => onClick(src)}
              alt=""
            />
          ))}
        </div>
      </div>
    </div>
  );
};
