import React from "react";

type props = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  onClick?: any;
};

export const Modal: React.FunctionComponent<props> = ({
  src,
  onClick,
  ...props
}) => {
  if (src.length > 0) {
    return (
      <div
        className="h-full w-full fixed right-0 z-40 top-0 bg-black bg-opacity-80"
        onClick={onClick}
      >
        <div className="webkit-center mt-28 md:mt-14 lg:mt-14 xl:mt-14 2xl:mt-14">
          <img
            {...props}
            className="border-white border-2 design-catalog-modal"
            src={src}
            alt=""
          />
          <h1 className="md:text-2xl design-catalog-modal-text bg-white hover:text-black italic cursor-pointer py-1 px-4">
            <a
              href="https://artfairnational.com/portfolio/32"
              target="_blank"
              rel="noreferrer"
            >
              Contact us for details
            </a>
          </h1>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
