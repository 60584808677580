import { useApi } from "../../api";
import { CONTENTFUL_CONTENT_RESPONSE } from "../../api/contentful";
import { useAsyncMemo } from "../../hooks/useAsyncMemo";

export const Services = () => {
  const api = useApi();
  const contentful = useAsyncMemo(
    async () => {
      const response = await api.contentful.getEntry("71qBUadbURAE987sY1A1hj");
      if (response.type !== CONTENTFUL_CONTENT_RESPONSE) return null;
      return response.data.fields;
    },
    [],
    null
  );

  if (contentful !== null) {
    return (
      <div className="h-full w-4/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 space-x-4 justify-center">
        <div className="flex flex-col md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:w-3/5 p-2 mb-1">
            <img className="w-full" src={contentful.image1} alt="" />
          </div>
          <div className="grid grid-cols-1 content-center self-stretch md:w-2/5 p-2 mb-1">
            <img className="w-full" src={contentful.image2} alt="" />
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row ml-none">
          <img
            className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
            src={contentful.image3}
            alt=""
          />
          <div className="grid grid-cols-1 content-center self-start md:p-2">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">{contentful.header1}</h1>
            <p className="text-left">{contentful.text1}</p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row ml-none">
          <img
            className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
            src={contentful.image4}
            alt=""
          />
          <div className="grid grid-cols-1 content-center self-start md:p-2">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">{contentful.header2}</h1>
            <p className="text-left">{contentful.text2}</p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row ml-none">
          <img
            className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
            src={contentful.image5}
            alt=""
          />
          <div className="grid grid-cols-1 content-center self-start md:p-2">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">{contentful.header3}</h1>
            <p className="text-left">{contentful.text3}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:p-2">
            <p className="text-left">{contentful.text4}</p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
