import { useApi } from "../../api";
import { CONTENTFUL_CONTENT_RESPONSE } from "../../api/contentful";
import { useAsyncMemo } from "../../hooks/useAsyncMemo";

export const Testimonials = () => {
  const api = useApi();
  const contentful = useAsyncMemo(
    async () => {
      const response = await api.contentful.getEntry("4mIfpHxNG9AeAqFku0sDtH");
      if (response.type !== CONTENTFUL_CONTENT_RESPONSE) return null;
      return response.data.fields;
    },
    [],
    null
  );

  if (contentful !== null) {
    return (
      <div className="h-full w-4/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 space-x-4 justify-center">
        <div className="flex flex-col md:flex-row ml-none justify-center">
          <h1 className="md:text-4xl text-2xl pb-2 text-center">
            {contentful.header1}
          </h1>
        </div>
        <div className="flex flex-col-reverse md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header2}
            </h1>
            <p className="py-2 text-left">{contentful.text1}</p>
          </div>
          {contentful.image1 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image1}
              alt=""
            />
          )}
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          {contentful.image2 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image2}
              alt=""
            />
          )}
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header3}
            </h1>
            <p className="py-2 text-left">{contentful.text2}</p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header4}
            </h1>
            <p className="py-2 text-left">{contentful.text3}</p>
          </div>
          {contentful.image3 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image3}
              alt=""
            />
          )}
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          {contentful.image4 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image4}
              alt=""
            />
          )}
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header5}
            </h1>
            <p className="py-2 text-left">{contentful.text4}</p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header6}
            </h1>
            <p className="py-2 text-left">{contentful.text5}</p>
          </div>
          {contentful.image5 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image5}
              alt=""
            />
          )}
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          {contentful.image6 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image6}
              alt=""
            />
          )}
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header7}
            </h1>
            <p className="py-2 text-left">{contentful.text6}</p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header8}
            </h1>
            <p className="py-2 text-left">{contentful.text7}</p>
          </div>
          {contentful.image7 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image7}
              alt=""
            />
          )}
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          {contentful.image8 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image8}
              alt=""
            />
          )}
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header9}
            </h1>
            <p className="py-2 text-left">{contentful.text8}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          {contentful.image9 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image9}
              alt=""
            />
          )}
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header10}
            </h1>
            <p className="py-2 text-left">{contentful.text9}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          {contentful.image10 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image10}
              alt=""
            />
          )}
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header11}
            </h1>
            <p className="py-2 text-left">{contentful.text10}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          {contentful.image11 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image11}
              alt=""
            />
          )}
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header12}
            </h1>
            <p className="py-2 text-left">{contentful.text11}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          {contentful.image12 && (
            <img
              className="w-3/5 sm:w-2/5 md:w-2/5 self-center p-2"
              src={contentful.image12}
              alt=""
            />
          )}
          <div className="grid grid-cols-1 content-center self-start md:p-2 min-w-50">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header13}
            </h1>
            <p className="py-2 text-left">{contentful.text12}</p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
