import { FacebookWithCircle } from "@styled-icons/entypo-social/FacebookWithCircle";

export const Footer = () => {
  return (
    <div className="flex flex-col md:flex-row ml-none justify-between footer">
      <div className="grid grid-cols-1">
        <h1 className="md:text-2xl text-left hover:text-white italic cursor-pointer py-1 px-4">
          <a
            className="float-left"
            href="https://mgrosser.jewelershowcase.com/"
            target="_blank"
            rel="noreferrer"
          >
            Diamonds Gemstones Online Catalog
          </a>
        </h1>
        <h1 className="md:text-2xl text-left hover:text-white italic cursor-pointer py-1 px-4">
          <a
            className="float-left"
            href="https://artfairnational.com/portfolio/32"
            target="_blank"
            rel="noreferrer"
          >
            Art Fair National
          </a>
        </h1>
      </div>
      <div className="grid grid-cols-1 self-center py-1 px-4">
        <a
          className="facebook"
          href="https://www.facebook.com/MGrosserJewelryDesign/"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookWithCircle
            style={{
              height: 45,
              width: 45,
            }}
          />
        </a>
      </div>
      <div className="grid grid-cols-1">
        <h1 className="md:text-3xl text-left hover:text-white py-1 px-4">
          <a href="tel:317-663-7000">(317) 663-7000</a>
        </h1>
        <p className="text-left text-white py-1 px-4 underline hover-text-theme">
          <a
            href="https://www.google.com/maps/place/580+E+Carmel+Dr+Suite+130,+Carmel,+IN+46032/@39.9637597,-86.1184415,17z/data=!3m1!4b1!4m5!3m4!1s0x8814ada236155555:0xc588f8d90797f39!8m2!3d39.9637556!4d-86.1162528"
            target="_blank"
            rel="noreferrer"
          >
            580 E Carmel Drive Suite 130 Carmel, IN 46032
          </a>
        </p>
        <p className="text-left text-white pt-1 pb-4 px-4">
          Please call for an appointment
        </p>
      </div>
    </div>
  );
};
