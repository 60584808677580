import { useApi } from "../../api";
import { CONTENTFUL_CONTENT_RESPONSE } from "../../api/contentful";
import { useAsyncMemo } from "../../hooks/useAsyncMemo";
import { useForm } from "react-hook-form";
import { Button } from "../../components/button";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Input } from "../../components/input";
import { TextArea } from "../../components/textarea";

export const Contact = () => {
  const api = useApi();
  const { register, handleSubmit } = useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formDataName, setFormDataName] = useState("");
  const [formDataEmail, setFormDataEmail] = useState("");
  const [formDataPhone, setFormDataPhone] = useState("");
  const [formDataMessage, setFormDataMessage] = useState("");
  const contentful = useAsyncMemo(
    async () => {
      const response = await api.contentful.getEntry("OWEASC8S9hNrsmEusUB7e");
      if (response.type !== CONTENTFUL_CONTENT_RESPONSE) return null;
      return response.data.fields;
    },
    [],
    null
  );

  const onSubmit = async () => {
    if (formDataEmail.length > 3) {
      let serviceId = "MGrosser_ServiceID";
      let templateId = "MGrosser_EmailTemplate";
      let message = {
        name: formDataName,
        message: formDataMessage,
        email: formDataEmail,
        phone: formDataPhone,
      };
      let userId = "user_YkeUYPp3zMdjM5oCw9ORi";

      emailjs.send(serviceId, templateId, message, userId).then(
        () => {
          setFormSubmitted(true);
        },
        () => {
          setFormError(true);
        }
      );
    }
  };

  if (contentful !== null) {
    return (
      <div className="h-full w-4/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 space-x-4 justify-center">
        <div className="flex flex-col md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:w-3/5 md:p-2 mb-1">
            <img className="w-full" src={contentful.image1} alt="" />
          </div>
          <div className="grid grid-cols-1 content-center self-stretch md:w-2/5 md:p-2 mb-1">
            <img className="w-full" src={contentful.image2} alt="" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:p-2">
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header1}
            </h1>
            <p className="py-2 text-left">{contentful.text1}</p>
            <h1 className="md:text-3xl text-2xl pb-1 text-left">
              {contentful.header2}
            </h1>
            <p className="py-2 text-left">{contentful.text2}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          {!formSubmitted ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="grid grid-cols-1 content-center self-start md:p-2  w-full"
            >
              <Input
                containerClass="py-2"
                name="name"
                type="text"
                label="Name"
                placeholder="name"
                onChange={(e) => setFormDataName(e.target.value)}
              />
              <Input
                containerClass="py-2"
                name="email"
                type="text"
                label="Email"
                placeholder="email"
                onChange={(e) => setFormDataEmail(e.target.value)}
              />
              <Input
                containerClass="py-2"
                name="phone"
                type="text"
                label="Phone"
                placeholder="(XXX) XXX-XXXX"
                onChange={(e) => setFormDataPhone(e.target.value)}
              />
              <TextArea
                name="message"
                label="message"
                placeholder="..."
                onChange={(e) => setFormDataMessage(e.target.value)}
              />
              <Button className="bg-black theme-color mt-4 items-center justify-center justify-self-end">
                Submit
              </Button>
            </form>
          ) : (
            <h1 className="md:text-3xl text-2xl p-1 text-left">
              {contentful.header3}
            </h1>
          )}
        </div>
        {formError && (
          <div className="flex flex-col md:flex-row ml-none">
            <div className="grid grid-cols-1 content-center self-start mb-1">
              <h1 className="md:text-3xl p-1 text-left">
                Sorry our email service is currently down!
              </h1>
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};
