import React from 'react'

type props = React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string
    className?: string
    labelClass?: string
    containerClass?: string
    innerRef?: any
    loading?: boolean
    disabled?: boolean
}

export const Input: React.FunctionComponent<props> = ({
    className,
    label,
    labelClass,
    containerClass,
    innerRef,
    ...props
}) => {
    return (
        <div className={`flex flex-col ${containerClass}`}>
            {label && (
                <label htmlFor={props.name} className={`mb-2 text-gray-700 ${labelClass}`}>
                    {label}
                </label>
            )}
            <input
                {...props}
                ref={innerRef}
                className={`px-4
                    py-2 border
                    border-primary
                    rounded-md
                    text-primary
                    focus:outline-none
                    focus:ring-4
                    focus:ring-black
                    focus:ring-opacity-25
                    focus:border-primary ${className}`}
            />
        </div>
    )
}
