import React from "react";

type props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  className?: string;
  labelClass?: string;
  containerClass?: string;
  placeholder?: string;
};

export const TextArea: React.FunctionComponent<props> = ({
  className,
  label,
  labelClass,
  containerClass,
  placeholder,
  ...props
}) => {
  return (
    <div className={`flex flex-col ${containerClass}`}>
      {label && (
        <label
          htmlFor={props.name}
          className={`mb-2 text-gray-700 pt-2 ${labelClass}`}
        >
          {label}
        </label>
      )}
      <textarea
        {...props}
        placeholder={placeholder}
        className={`px-4
                    py-2 border
                    border-primary
                    rounded-md
                    text-primary
                    focus:outline-none
                    focus:ring-4
                    focus:ring-black
                    focus:ring-opacity-25
                    focus:border-primary ${className}`}
      />
    </div>
  );
};
