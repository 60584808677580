import { useState } from "react";
import { useApi } from "../api";
import { CONTENTFUL_CONTENT_RESPONSE } from "../api/contentful";
import { useAsyncMemo } from "../hooks/useAsyncMemo";

export const Navbar = () => {
  const [responsive, setResponsive] = useState(false);
  const api = useApi();
  const contentful = useAsyncMemo(
    async () => {
      const response = await api.contentful.getEntry("2TJWxXaiazieGHcysNhIGV");
      if (response.type !== CONTENTFUL_CONTENT_RESPONSE) return null;
      return response.data.fields;
    },
    [],
    null
  );

  if (contentful !== null) {
    return (
      <div
        className={
          !responsive ? "navbar bg-black" : "navbar bg-black responsive"
        }
      >
        <a href="/home/3040677">
          <img
            src="/images/title-image.png"
            alt="M. Grosser Jewelry Design | Custom Jewelry | Indianapolis IN"
            title="M. Grosser Jewelry Design | Custom Jewelry | Indianapolis IN"
          />
        </a>
        <a href="/about-our-designer/3041897" className="px-7 py-10 text-white">
          {contentful.text1}
        </a>
        <a href="/design-catalog/3040680" className="px-7 py-10 text-white">
          {contentful.text2}
        </a>
        <a href="/upcoming-events/3075154" className="px-7 py-10 text-white">
          {contentful.text3}
        </a>
        <a href="/services/3041898" className="px-7 py-10 text-white">
          {contentful.text4}
        </a>
        <a href="/testimonials" className="px-7 py-10 text-white">
          {contentful.text5}
        </a>
        <a href="/contact-us/3040681" className="px-7 py-10 text-white">
          {contentful.text6}
        </a>
        <a
          href="#!"
          style={{ fontSize: 30, cursor: "pointer" }}
          onClick={() => setResponsive(!responsive)}
          className="px-2 py-2 icon"
        >
          &#9776;
        </a>
      </div>
    );
  }

  return null;
};
