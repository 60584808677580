import { useApi } from "../../api";
import { CONTENTFUL_CONTENT_RESPONSE } from "../../api/contentful";
import { useAsyncMemo } from "../../hooks/useAsyncMemo";

export const Home = () => {
  const api = useApi();
  const contentful = useAsyncMemo(
    async () => {
      const response = await api.contentful.getEntry("6ZBq9NGwZbLS7u5XoHOjBl");
      if (response.type !== CONTENTFUL_CONTENT_RESPONSE) return null;
      return response.data.fields;
    },
    [],
    null
  );

  if (contentful !== null) {
    return (
      <div className="h-full w-4/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 space-x-4 justify-center">
        <div className="flex justify-center">
          <video className="flex-auto md:px-2 pb-1 justify-self-center" controls>
            <source
              src="https://videos.hibustudio.com/2015/m-grosser-jewelry-design-htpnl8zzw-3176637000.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="flex flex-col md:flex-row ml-none">
          <div className="grid grid-cols-1 content-center self-start md:w-3/5 md:p-2">
            <img className="w-full" src={contentful.image1} alt="" />
            <h1 className="md:text-3xl text-2xl pb-1 text-center">{contentful.header1}</h1>
            <p className="py-2 text-center">{contentful.text1}</p>
          </div>
          <div className="grid grid-cols-1 content-center md:self-start md:w-2/5 md:p-2">
            <img className="w-full" src={contentful.image2} alt="" />
            <img className="w-full py-2" src={contentful.image3} alt="" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 content-center md:p-2">
          <div className="col-span1 webkit-center px-1 py-1">
            <img src={contentful.image4} alt="" />
            <h1 className="text-2xl py-1">{contentful.header2}</h1>
            <p className="py-1">{contentful.text2}</p>
          </div>
          <div className="col-span1 webkit-center px-1">
            <img src={contentful.image5} alt="" />
            <h1 className="text-2xl py-1 px-1">
              {contentful.header3}
            </h1>
            <p className="py-1 px-1">{contentful.text3}</p>
          </div>
          <div className="col-span1 webkit-center px-1">
            <img src={contentful.image6} alt="" />
            <h1 className="text-2xl py-1 px-1">
              {contentful.header4}
            </h1>
            <p className="py-1 px-1">{contentful.text4}</p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
